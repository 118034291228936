<template>
    <div>
        <!-- 财务账单 FinancialStatement_Component -->
        <FinancialStatement_Component></FinancialStatement_Component>
    </div>
</template>

<script>
import FinancialStatement_Component from '../../components/PersonalCenter/FinancialStatement_Component.vue'
export default {
    name: "FinancialStatement",
    components: { FinancialStatement_Component }
}
</script>

<style>

</style>