<template>
    <div class="FinancialStatement">
        <!-- 财务帐单  -->
        <div class="fromInputs">
            <ul>
                <li>
                    <div class="packingTime imports">
                        <span>装箱时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="packingTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <!-- <div class="packingTime" v-show="flagOpen">
                        <span>离港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="departureTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div> -->
                    <div class="packingTime" v-show="flagOpen">
                        <span>到港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="arrivalTimeValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div>
                    <!-- <div class="packingTime" v-show="flagOpen">
                        <span>到门时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="dtoTheDoorValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div> -->
                    <div class="packingTime" v-show="flagOpen">
                        <span>收货人：</span>
                        <div class="datePicker">
                            <el-input v-model="consigneeValue" placeholder="请输入收货人"></el-input>
                        </div>
                    </div>
                    <div class="packingTime" v-show="flagOpen">
                        <span>订单类型：</span>
                        <div class="datePicker">
                            <el-select v-model="orderTypes" placeholder="请选择订单类型">
                                <el-option label="海运订单" value="海运订单"></el-option>
                                <el-option label="铁路订单" value="铁路订单"></el-option>
                                <el-option label="空运订单" value="空运订单"></el-option>
                            </el-select>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="packingTime" v-show="flagOpen">
                        <span class="flexs">发货城市：</span>
                        <div class="datePicker">
                            <el-input v-model="shippingCityValue" placeholder="请输入发货城市"></el-input>
                        </div>
                    </div>

                    <div class="packingTime" v-show="flagOpen">
                        <span>收货城市：</span>
                        <div class="datePicker">
                            <el-input v-model="shipToCityValue" placeholder="请输入收货城市"></el-input>
                        </div>
                    </div>

                    <!-- <div class="packingTime" v-show="flagOpen">
                        <span>装箱号：</span>
                        <div class="datePicker">
                            <el-input v-model="packingNoValue" placeholder="请输入装箱号"></el-input>
                        </div>
                    </div> -->
                    <div class="packingTime" v-show="flagOpen">
                        <span>合同号：</span>
                        <div class="datePicker">
                            <el-input v-model="contractNoValue" placeholder="请输入合同号"></el-input>
                        </div>
                    </div>

                </li>
                <li>
                    <!-- <div class="packingTime" v-show="flagOpen">
                        <span class="flexs">预计到港时间：</span>
                        <div class="datePicker">
                            <el-date-picker v-model="estimatedTimeOfArriValue" type="daterange" range-separator="~"
                                value-format="yyyy-MM-dd" start-placeholder="请选择起始值" end-placeholder="请选择结束值">
                            </el-date-picker>
                        </div>
                    </div> -->

                    <div class="packingTime" v-show="flagOpen">
                        <span>提单号：</span>
                        <div class="datePicker">
                            <el-input v-model="blNoValue" placeholder="请输入提单号"></el-input>
                        </div>
                    </div>


                    <div class="packingTime" v-show="flagOpen">
                        <span>发票号：</span>
                        <div class="datePicker">
                            <el-input v-model="invoiceNoValue" placeholder="请输入发票号"></el-input>
                        </div>
                    </div>

                    <div class="fromBtn packingTime" :class="{ 'flagActive': !flagOpen }">
                        <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                        <el-button type="primary" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
                        <el-button type="primary" :icon="flagOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                            @click="handleopen">{{ flagOpen ? '收起' : '展开' }}</el-button>
                    </div>
                </li>
            </ul>

        </div>

        <div class="tables">
            <!-- <el-table :data="tableDataList" style="width: 100%" border :span-method="objectSpanMethod"
                :row-style="{ height: '50px' }">
                <el-table-column label="合同号" width="200">
                    <template slot-scope="scope">
                        <p v-if="scope.row.contract_no == scope.row.contract_no">{{ scope.row.contract_no }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="起运地" width="100">
                    <template slot-scope="scope">
                        <p v-if="scope.row.departure == scope.row.departure">{{ scope.row.departure }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="目的地" width="100">
                    <template slot-scope="scope">
                        <p v-if="scope.row.destination == scope.row.destination">{{ scope.row.destination }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="费用名称" prop="detailName" class-name="nanas">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ scope.row.detailName }}</span>
                            <span v-if="scope.row.total">：{{ scope.row.detailVal[0].currency | getCurrency }}{{
                                    scope.row.total
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="费用明细">
                    <template slot-scope="scope" prop="detailVal">
                        <div v-if="scope.row.detailVal">
                            <p v-for="(item, index) in scope.row.detailVal" :key="index">
                                <span>{{ item.name }}</span>：
                                <span>{{ item.currency | getCurrency }}&nbsp;</span>
                                <span>{{ item.price }}</span>
                            </p>
                        </div>
                        <div v-else>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="备注">
                    <template slot-scope="scope" prop="detailVal">
                        <div v-if="scope.row.detailVal">
                            <p v-for="(item, index) in scope.row.detailVal" :key="index">
                                <span>{{ item.note }}</span>
                            </p>
                        </div>
                        <div v-else>
                        </div>
                    </template>
                </el-table-column>
            </el-table> -->

            <el-table :data="tableDataList" style="width: 100%" border :span-method="objectSpanMethod"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '50px' }">
                <el-table-column label="合同号" width="200">
                    <template slot-scope="scope">
                        <p v-if="scope.row.contract_no == scope.row.contract_no">{{ scope.row.contract_no }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="起运地" width="100">
                    <template slot-scope="scope">
                        <p v-if="scope.row.departure == scope.row.departure">{{ scope.row.departure }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="目的地" width="100">
                    <template slot-scope="scope">
                        <p v-if="scope.row.destination == scope.row.destination">{{ scope.row.destination }}</p>
                    </template>
                </el-table-column>

                <el-table-column label="费用名称" class-name="nanass">
                    <template slot-scope="scope">
                        <div v-if="scope.row.detail">
                            <div class="divs"
                                :style="`height:${heigth * item.detail.length}px;lineHeight: ${heigth * item.detail.length}px;`"
                                :class="{ borderbottom: item.detail.length > 1 }"
                                v-for="(item, index) in scope.row.detail" :key="index" v-show="item.detail.length > 0">
                                <p>
                                    <span> {{ item.name }}</span>
                                    <!-- <span> {{ item.detail.length }}</span> -->
                                </p>

                            </div>

                            <!-- <span v-if="scope.row.total">：{{ scope.row.detailVal[0].currency | getCurrency }}{{
                                    scope.row.total
                            }}</span> -->
                            <!-- <el-table :data='scope.row.detail' :show-header="false">
                                <template v-if="scope.row.detail.length > 0">
                                    <el-table-column prop='name' class-name="nasnas">
                                        <template slot-scope="scopes">
                                            <div v-if="scopes.row.detail.length > 0">
                                                <div>{{ scopes.row.name }}</div>
                                            </div>

                                        </template>
                                    </el-table-column>
                                </template>

                            </el-table> -->
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="费用明细" class-name="nanass">
                    <template slot-scope="scope">
                        <div v-if="scope.row.detail">
                            <div v-for="(item, index) in scope.row.detail" :key="index">
                                <template v-if="item.detail.length > 0">
                                    <div class="divs" :style="`height:${heigth * item.detail.length}px;`"
                                        :class="{ borderbottom: item.detail.length > 1 }">
                                        <p v-for="(items, index) in item.detail" :key="index"
                                            v-show="item.detail.length > 0">
                                            <span>{{ items.name }}:{{ items.currency | getCurrency }}{{ items.price
                                            }}</span>
                                        </p>
                                    </div>
                                </template>
                            </div>
                            <!-- <span>{{ scope.row.detail }}</span> -->
                            <!-- <span v-if="scope.row.total">：{{ scope.row.detailVal[0].currency | getCurrency }}{{
                                    scope.row.total
                            }}</span> -->
                            <!-- <el-table :data='scope.row.detail' :show-header="false">
                                <el-table-column prop='name' v-if="scope.row.detail.length > 0" class-name="nasnas">
                                    <template slot-scope="scopes">
                                        <div v-if="scopes.row.detail.length === 0">
                                            
                                        </div>
                                        <div v-else>{{ scopes.row.name }}</div>
                                    </template>
                                </el-table-column>
                            </el-table> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="备注" class-name="nanass">
                    <template slot-scope="scope">
                        <div v-if="scope.row.detail">
                            <div v-for="(item, index) in scope.row.detail" :key="index">
                                <template v-if="item.detail.length > 0">
                                    <div class="divs" :style="`height:${heigth * item.detail.length}px;`"
                                        :class="{ borderbottom: item.detail.length > 1 }">
                                        <p v-for="(items, index) in item.detail" :key="index"
                                            v-show="item.detail.length > 0">
                                            <!-- <span>{{ items.name }}:{{ items.currency | getCurrency }}{{ items.price
                                            }}</span> -->
                                            <span>{{ items.note || "暂无" }}</span>
                                        </p>
                                    </div>
                                </template>
                            </div>
                        </div>

                    </template>
                </el-table-column>
            </el-table>


            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "FinancialStatement_Component",
    lastEndIndex: 0,
    lastStartIndex: 0,
    currentBillPeriodTotalFee: 0,
    data() {
        return {
            heigth: 30,
            billingDateRangeVal: "",
            orderTypeVal: "",
            options: [{
                value: '选项1',
                label: '黄金糕'
            }],
            hasChildren: true,
            tableDataList: [], //表格数据
            flagOpen: false,
            maxheight: 500,
            packingTimeValue: [], //装箱时间
            departureTimeValue: "", //离港时间
            estimatedTimeOfArriValue: "",//预计到港时间
            arrivalTimeValue: "",//到港时间
            dtoTheDoorValue: "", //到门时间
            consigneeValue: "",//收货人
            shippingCityValue: "",//发货城市
            shipToCityValue: "",//收货城市
            contractNoValue: "",//合同号
            orderTypes: '',
            blNoValue: "",//提单号
            packingNoValue: "",//装箱号
            invoiceNoValue: "",//发票号
            orderDetailList: [],
            isDetail: true,

            paging: {
                total: 0,
                size: 5,
            },
            datas: {},
            expands: [],

            spanArr: [],

            position: 0
        }
    },
    created() {
        this.postFeeListByContract();
    },
    methods: {
        postFeeListByContract() {
            var token = sessionStorage.getItem("token")
            axios.post(`${baseUrl}/salesReport/feeListByContract`, {


            }, {
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    if (res.data.success == true) {
                        console.log(res.data.result.records);
                        this.paging.total = res.data.result.total
                        this.tableDataList = res.data.result.records;
                        // this.eachList(res.data.result.records);

                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        eachList(tabList) {
            // let tabList = res.data.result;
            tabList.forEach((tabVal, tabIdx) => {
                let contract_no = tabList[tabIdx].contract_no; //合同号
                let departure = tabVal.departure;
                let destination = tabVal.destination;

                // if (tabVal.detail.length > 0) {
                tabVal.detail.forEach((detailVal, detailIdx) => {
                    // console.log(detailVal);
                    // let detailName = detailVal.name; //费用名称
                    var detailName = ''
                    let total = detailVal.total;
                    if (detailVal.detail.length > 0) {
                        detailName = detailVal.name;
                        this.datas = {
                            departure,
                            destination,
                            total,
                            contract_no,
                            detailName,
                            detailVal: detailVal.detail
                        };
                    } else {
                        detailName = '';
                        this.datas = {
                            departure,
                            destination,
                            total,
                            contract_no,
                            detailName,
                            detailVal: detailVal.detail
                        };
                    }


                    // this.datas = {
                    //     contract_no,
                    //     // detailName
                    // }

                    // detailVal.detail.forEach((feiyongVal, feiyongIdx) => {

                    // let feiyongName = [feiyongVal];
                    // console.log(feiyongVal);


                    // });
                    this.tableDataList.push(this.datas);
                    // console.log(this.tableDataList);
                })
                // }

            })
        },


        handleSearch() {
            // console.log(this.billingDateRangeVal);
        },
        handleQuery() { //查询
            var token = sessionStorage.getItem("token");
            if (this.packingTimeValue) {
                var packingTimeValue1 = this.packingTimeValue[0]
                var packingTimeValue2 = this.packingTimeValue[1]
            }
            if (this.arrivalTimeValue) {
                var arrivalTimeValue1 = this.arrivalTimeValue[0]
                var arrivalTimeValue2 = this.arrivalTimeValue[1]
            }
            axios.get(`${baseUrl}/salesReport/feeListByContract`,
                {
                    params: {
                        pick_up_time_begin: packingTimeValue1,   //装箱时间
                        pick_up_time_end: packingTimeValue2,
                        arrival_time_begin: arrivalTimeValue1,
                        arrival_time_end: arrivalTimeValue2, //到港时间
                        bill_no: this.blNoValue, //提单号
                        invoice_no: this.invoiceNoValue,//发票号
                        contract_no: this.contractNoValue,//合同号
                        froms: this.shippingCityValue,//froms
                        tos: this.shipToCityValue,//tos
                        customer: this.consigneeValue, //收货人
                        orderType: this.orderTypes,// 订单类型

                    },
                    headers: {
                        'authorization': token
                    }
                }
                ,

            )
                .then(res => {
                    if (res.data.success == true) {
                        console.log(res.data.result.records);
                        this.tableDataList = res.data.result.records;
                        this.paging.total = res.data.result.total
                        // this.eachList(res.data.result.records);

                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleReset() { //重置
            this.packingTimeValue = this.arrivalTimeValue = this.blNoValue = this.invoiceNoValue = this.contractNoValue = this.shippingCityValue = this.shipToCityValue = this.consigneeValue = this.orderTypes = ''
        },
        handleExport() { //导出
            var token = sessionStorage.getItem("token");
            if (this.packingTimeValue) {
                var packingTimeValue1 = this.packingTimeValue[0]
                var packingTimeValue2 = this.packingTimeValue[1]
            }
            if (this.arrivalTimeValue) {
                var arrivalTimeValue1 = this.arrivalTimeValue[0]
                var arrivalTimeValue2 = this.arrivalTimeValue[1]
            }
            axios.get(`${baseUrl}/salesReport/exportExcel`, {
                params: {
                    pick_up_time_begin: packingTimeValue1 || "",  //装箱时间起始值
                    pick_up_time_end: packingTimeValue2 || "",    //装箱时间结束值
                    arrival_time_begin: arrivalTimeValue1,  //到港时间
                    arrival_time_end: arrivalTimeValue2,
                    froms: this.shippingCityValue,//发货城市
                    tos: this.shipToCityValue,//收货城市
                    contract_no: this.contractNoValue,//合同号
                    bill_no: this.blNoValue,//提单号
                    packing_no: this.packingNoValue, //装箱号
                    invoice_no: this.invoiceNoValue,//发票号
                    customer: this.consigneeValue, //收货人
                    orderType: this.orderTypes,//订单类型	
                },
                headers: {
                    'authorization': token,
                    responseType: 'blob',
                    'Content-type': 'application/json;'
                },
                responseType: 'blob',
            }).then(res => {
                console.log("222", res);
                const xlsx = 'application/vnd.ms-excel'
                var blob = new Blob([res.data], { type: xlsx });
                var downloadElement = document.createElement('a');
                console.log(blob);
                var href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download = '财务账单.xlsx'; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象
            })
        },
        handleopen() { //展开
            this.flagOpen = !this.flagOpen
        },

        rowspan() {
            this.tableDataList.forEach((item, index) => {
                if (index === 0) {
                    this.spanArr.push(1);
                    this.position = 0;
                } else {
                    //这里是判断的条件
                    if ((this.tableDataList[index].name === this.tableDataList[index - 1].name) && (this.tableDataList[index].channel === this.tableDataList[index - 1].channel)) {
                        this.spanArr[this.position] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.position = index;
                    }
                }
            });
        },

        objectSpanMethod({ row, column, rowIndex, columnIndex }) {

            // if (columnIndex === 0) {
            //     if (rowIndex % 5 === 0) {
            //         return {
            //             rowspan: 5,
            //             colspan: 1
            //         };
            //     } else {
            //         return {
            //             rowspan: 0,
            //             colspan: 0
            //         };
            //     }
            // } else if (columnIndex === 1) {
            //     if (rowIndex % 5 === 0) {
            //         return {
            //             rowspan: 5,
            //             colspan: 1
            //         };
            //     } else {
            //         return {
            //             rowspan: 0,
            //             colspan: 0
            //         };
            //     }
            // } else if (columnIndex === 2) {
            //     if (rowIndex % 5 === 0) {
            //         return {
            //             rowspan: 5,
            //             colspan: 1
            //         };
            //     } else {
            //         return {
            //             rowspan: 0,
            //             colspan: 0
            //         };
            //     }
            // }
        },

        handleSizeChange(val) {
            // this.tableDataList = [];
            console.log(val);
            var token = sessionStorage.getItem("token");
            if (this.packingTimeValue) {
                var packingTimeValue1 = this.packingTimeValue[0]
                var packingTimeValue2 = this.packingTimeValue[1]
            } else {
                var packingTimeValue1 = ''
                var packingTimeValue2 = ''
            }
            if (this.arrivalTimeValue) {
                var arrivalTimeValue1 = this.arrivalTimeValue[0]
                var arrivalTimeValue2 = this.arrivalTimeValue[1]
            } else {
                var arrivalTimeValue1 = ''
                var arrivalTimeValue2 = ''
            }
            axios.post(`${baseUrl}/salesReport/feeListByContract`, {
                pageNo: val,
                pick_up_time_begin: packingTimeValue1,   //装箱时间
                pick_up_time_end: packingTimeValue2,
                arrival_time_begin: arrivalTimeValue1,
                arrival_time_end: arrivalTimeValue2, //到港时间
                bill_no: this.blNoValue, //提单号
                invoice_no: this.invoiceNoValue,//发票号
                contract_no: this.contractNoValue,//合同号
                froms: this.shippingCityValue,//froms
                tos: this.shipToCityValue,//tos
                customer: this.consigneeValue, //收货人
                orderType: this.orderTypes,// 订单类型

            }, {
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    if (res.data.success == true) {
                        this.tableDataList = res.data.result.records;
                        // let tabList = res.data.result.records;
                        // tabList.forEach((tabVal, tabIdx) => {
                        //     let contract_no = tabList[tabIdx].contract_no; //合同号
                        //     let departure = tabVal.departure;
                        //     let destination = tabVal.destination
                        //     tabVal.detail.forEach((detailVal, detailIdx) => {

                        //         let detailName = detailVal.name; //费用名称
                        //         let total = detailVal.total;
                        //         if (detailVal.detail.length > 0) {
                        //             detailName = detailVal.name;
                        //             this.datas = {
                        //                 departure,
                        //                 destination,
                        //                 total,
                        //                 contract_no,
                        //                 detailName,
                        //                 detailVal: detailVal.detail
                        //             };
                        //         } else {
                        //             detailName = '';
                        //             this.datas = {
                        //                 departure,
                        //                 destination,
                        //                 total,
                        //                 contract_no,
                        //                 detailName,
                        //                 detailVal: detailVal.detail
                        //             };
                        //         }
                        //         // this.datas = {
                        //         //     contract_no,
                        //         //     detailName
                        //         // }
                        //         // console.log(tabVal);
                        //         // detailVal.detail.forEach((feiyongVal, feiyongIdx) => {

                        //         // let feiyongName = [feiyongVal];
                        //         // console.log(feiyongVal);
                        //         // this.datas = {
                        //         //     departure,
                        //         //     destination,
                        //         //     total,
                        //         //     contract_no,
                        //         //     detailName,
                        //         //     detailVal: detailVal.detail
                        //         // };

                        //         // });
                        //         this.tableDataList.push(this.datas);
                        //         // console.log(this.tableDataList);
                        //     })
                        // })
                        // console.log(res);

                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }


    },
}
</script>

<style lang="scss" scoped>
.borderbottom {
    border-bottom: 1px solid rgba(235, 238, 245);
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

::v-deep .el-form-item {
    margin-bottom: 0;
}

// ::v-deep .el-table tr:nth-child(2n-1) {
//     background: #F4F4F4;
// }

.flagActive {
    margin-top: 0 !important;
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .nanas {
    // width: 48px !important;
    height: 48px !important;
    // position: absolute;
    width: 100%;
    // height: 50px !important;
    // margin-top: 1px;
    // border-top: 1px solid #EBEEF5;
}

::v-deep .nanass {
    padding: 0;

    .cell {
        padding: 0;
        // height: 100% !important;
        // min-height: 50px;

        // p {
        //     height: 30px;
        //     line-height: 30px;
        // }
    }

    .divs {
        // border-bottom: 1px solid rgba(235, 238, 245);
        margin-top: 5px;
        line-height: 30px;
        padding: 0 10px;
        line-height: 30px;
    }


}

::v-deep .nasnas {
    border: none !important;
}

.FinancialStatement {
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    // padding-left: 32px;
    margin: 0 auto;
    // min-height: 690px;

    .fromInputs {
        padding: 0 24px;

        .fromInputsTop {
            display: flex;
            justify-content: flex-start;

            .imports {
                span {
                    width: 70px;
                }
            }

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;

                span {
                    // flex: 1;
                    max-width: 100px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    white-space: nowrap;
                    text-align: right;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsCenter {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;

            .packingTime {
                // width: 354px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;


                span {
                    // flex: 1;
                    max-width: 100px;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    text-align: right;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }

                .datePickerSmall {
                    width: 140px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsBottom {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 10px;


                span {
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        .fromInputsBottom2 {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;
            align-items: center;

            .packingTime {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-left: 10px;


                span {
                    flex: 1;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .datePicker {
                    width: 284px;
                    height: 40px;

                    .el-date-editor {
                        width: 100%;
                        height: 100%;

                    }
                }
            }

            .fromBtn {
                // margin-left: 24px;
                display: flex;
                justify-content: flex-end;

                .el-button:nth-child(1) {
                    background-color: #FFFFFF;
                    border: 0;
                    color: #40a9ff;
                }
            }
        }

        ul {
            display: flex;
            justify-content: space-between;
            // padding: 0 24px;

            li {

                .packingTime {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 24px;

                    // margin-left: 10px;
                    span {
                        // flex: 1;
                        // max-width: 100px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        white-space: nowrap;
                        text-align: right;
                    }

                    .datePicker {
                        width: 284px;
                        height: 40px;

                        .el-date-editor {
                            width: 100%;
                            height: 100%;

                        }
                    }
                }

                .packingTime:nth-child(1) {
                    margin-top: 0;
                }

                .fromBtn {
                    // margin-left: 24px;
                    display: flex;
                    justify-content: flex-end;

                    .el-button:nth-child(4) {
                        background-color: #FFFFFF;
                        border: 0;
                        color: #40a9ff;
                    }
                }
            }
        }


    }

    .tables {
        margin-top: 54px;
        padding: 0 24px 32px;



        ::v-deep .el-form-item__content {
            float: right;
            // margin-left: 490px;
            width: 50%;
            padding-left: 35px;
        }

        // ::v-deep .el-icon-arrow-right:before {
        //     content: "\e723";
        // }

        // ::v-deep .expanded {
        //     .el-icon-arrow-right:before {
        //         content: "\e722";
        //     }
        // }

        .paging {
            display: flex;
            justify-content: flex-end;
            position: relative;
            // float: right;
            margin-top: 32px;
            // background-color: #FFFFFF;
        }
    }




}
</style>